import React from "react"
import { useEffect } from "react"
import styles from "../Impressum/Impressum.module.scss"
import content from "../../content/content.json"

const Impressum = () => {

	useEffect(() => {
		setTimeout(function () {
			window.scrollTo(0, 0)
        },250);
	})

    return (
        <section className={styles.Impressum}>
            <article className={styles.Impressum__Copy}>
                <h1>{content.pages.impressum.headline}</h1>
                <p dangerouslySetInnerHTML={{ __html: content.pages.impressum.main_copy }}></p>
                <p><a href={content.pages.impressum.contact.target}>{content.pages.impressum.contact.label}</a></p>
                <p dangerouslySetInnerHTML={{ __html: content.pages.impressum.secondary_copy }}></p>
                <p>
                    {content.pages.impressum.links.map((link, i) => 
                        <a key={i} href={link.target}>{link.label}</a>
                    )}
                </p>
            </article>
        </section>
    );
}

export default Impressum;